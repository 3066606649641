import React from 'react';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import './notfound.css';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <Header />
      <div className="not-found">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>The page you're looking for doesn't exist.</p>
        <Link to="/" className="home-link">
          Back to Home
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
