import React from "react";
import PropTypes from 'prop-types';
import "./form.css";
import { useForm, ValidationError } from "@formspree/react";

function ContactForm() {
  const [state, handleSubmit] = useForm("xwkddyld");

  if (state.succeeded) {
    return (
      <div className="form-success">
        <h3>Thanks! I'll get back to you soon.</h3>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input 
          id="name" 
          type="text" 
          name="name" 
          required 
          aria-label="Name"
        />
        <ValidationError prefix="Name" field="name" errors={state.errors} />
      </div>

      <div className="form-group">
        <label htmlFor="email">Email Address</label>
        <input 
          id="email" 
          type="email" 
          name="email" 
          required
          aria-label="Email Address" 
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>

      <div className="form-group">
        <label htmlFor="message">Message</label>
        <textarea 
          id="message" 
          name="message" 
          required
          aria-label="Message"
        />
        <ValidationError prefix="Message" field="message" errors={state.errors} />
      </div>

      <button 
        type="submit" 
        disabled={state.submitting}
        className={state.submitting ? 'submitting' : ''}
      >
        {state.submitting ? 'Sending...' : 'Submit'}
      </button>
    </form>
  );
}

export default ContactForm;
